import axios from "axios";
import {toast} from "react-toastify";

axios.interceptors.response.use((res) => {
  if(res?.data?.messages){
    res.data.messages.each(msg => {
      toast.success(msg);
    });
  }

  return res;
}, function (res) {
  if(res?.response?.data?.messages){
    res.response.data.messages.each(msg => {
      toast.error(msg);
    });
  }

  if(res?.response?.data?.msg){
    toast.error(res?.response?.data?.msg);
  }

  if([500, 400].includes(res?.response?.status) && res?.response?.data?.['hydra:description']){
    toast.error(res?.response?.data?.['hydra:description']);
  }

  return Promise.reject(res);
});
