export const supervisorPrefix = '/panel-zarzadzania';

const routes = {
  main: supervisorPrefix,
  home: `${supervisorPrefix}/home`,
  login: `${supervisorPrefix}/logowanie`,

  supervisorWorkshop: {
    list: `${supervisorPrefix}/warsztaty/lista`,
  }
};

export default routes;