export const adminPrefix = '/admin73328793';

const routesAdmin = {
  home: `${adminPrefix}/dashboard`,
  login: `${adminPrefix}/logowanie`,

  workshops: {
    main: `${adminPrefix}/warsztaty`,
  },

  users: {
    main: `${adminPrefix}/uzytkownicy`,
  },

  userTasks: {
    main: `${adminPrefix}/zadania`,
  },

  workshopPlanTransactions: `${adminPrefix}/lista-transakcji`,
};

export default routesAdmin;
