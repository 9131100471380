import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import {getLanguage} from "../i18n/init";

export default (i18nextLng) => {
  const language = getLanguage(i18nextLng) ?? {countryCode: 'pl', langCode: 'PL'}

  axios.defaults.headers.common.locale = `${language.countryCode}-${language.langCode}`
  i18n.changeLanguage(`${language.countryCode}-${language.langCode}`)
  moment.locale(language.langCode)
}